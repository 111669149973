<template>
  <div v-if="item">
    <v-row>
      <v-col cols="12" sm="6">
        <vx-card-list title="Basic info" :item="item">
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item._id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Name </v-list-item-title>
            <v-list-item-subtitle> {{ item.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Slug </v-list-item-title>
            <v-list-item-subtitle> {{ item.slug }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>
      </v-col>

      <v-col cols="12" sm="6">
        <vx-card-list title="References" :item="item" hide-edit>
          <v-list-item :to="$toList('page-schema', { 'components.schemaId': item._id })">
            <v-list-item-title> Page schemas </v-list-item-title>
            <v-list-item-subtitle> {{ count.pageSchemas }} </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>

        <vx-card-list title="Preview" :item="item">
          <vx-img rounded preview rectangle :src="item.url" />
        </vx-card-list>
      </v-col>

      <v-col cols="12">
        <vx-card-json :item="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ComponentSchemaService } from '@tutti/services';

export default {
  data() {
    return {
      item: null,
      count: {},
    };
  },
  created() {
    this.getFile();
    this.getCount();
  },
  methods: {
    async getFile() {
      const response = await ComponentSchemaService.getByIdAdmin(this.$route.params.id);
      if (response) {
        this.item = response.data;
        this.$setTitle(this.item.name);
      }
    },
    async getCount() {
      const response = await ComponentSchemaService.count(this.$route.params.id);
      if (response) {
        this.count = response.data;
      }
    },
  },
};
</script>
